import {useForm, FormProvider} from "react-hook-form";

import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {
    signup,
    getVerificationCode,
    setLoading
} from "../../../Reducers/Auth2";
import {setErrors} from "../../../Reducers/Errors"

import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from '../../../hooks/useRecaptcha';

import AuthBody from "../AuthBody";
import BrandNav from "../BrandNav";
import ErrorMessage from "../../../Components/Errors/ErrorMessage";
import Footer from "../../../Components/Footer/Footer";
import PassInput from "../PassInput";
import {ListGroup} from "react-bootstrap";
import {validatePass} from "../../../Utils/utils";
import BorderedSpinner from "../../../Components/Spinner/Spinner";
import {CSRF} from "../repository"

const reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY;

const SignUpContent = () => {

    const dispatch = useDispatch();
    const {verificationCodeSent, loading} = useSelector(state => state.Auth2)
    // const isLoading = useSelector(state => state.Auth2.loading)

    const emailMatch = "([-!#-'*+/-9=?A-Z^-~]+(\\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \\t]|(\\\\[\\t -~]))+\")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])"

    const methods = useForm()
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: {errors}
    } = methods

    const {capchaToken, recaptchaRef, handleRecaptcha, refreshCaptcha} = useRecaptcha();

    const onSubmitHandler = (formValues) => {

        const passErrors = validatePass(formValues.password1, formValues.password2)

        if (passErrors.length > 0) {
            dispatch(setErrors(passErrors))
            setError("password1", {type: "notValid"}, {shouldFocus: true})
            setError("password2", {type: "notValid"}, {shouldFocus: true})
        } else if (formValues.phone_number.match('^380[0-9]{9}') == null) {
            setError("phone_number", {type: "notValid"}, {shouldFocus: true})
        } else if (!(formValues.username.match(emailMatch))) {
            setError("username", {type: "notValid"}, {shouldFocus: true})
        } else {
            if (verificationCodeSent) {
                dispatch(setLoading(true))
                dispatch(signup(formValues))
            } else {
                dispatch(setLoading(true))
                dispatch(getVerificationCode({
                    phone_number: formValues.phone_number,
                    username: formValues.username,
                    capcha_token: formValues.capcha_token
                }))
            }
        }

        // Reset captcha after submission
        // recaptchaRef.current?.reset();
        refreshCaptcha()
    }

    useEffect(() => {
        setValue("capcha_token", capchaToken)
        register("capcha_token", {required: true})
    }, [capchaToken]);

    return (
        <div className="auth-content py-md-0 py-8">
            {loading ?
                <div className="row mb-5">
                    <div className="col-lg-10 mx-auto d-flex position-absolute justify-content-center z-index-10">
                        <BorderedSpinner/>
                    </div>
                </div> : <></>
            }
            <FormProvider {...methods}>
                <form noValidate={true} className="w-100" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h4 className="mb-4 text-center">Зареєструватися в кабінет електронних послуг</h4>
                            <div className="row gx-3">
                                <fieldset disabled={loading}>
                                    <div className="form-group col-lg-12">
                                        <div className="form-label-group">
                                            <label htmlFor="username">Логін (адреса електронної пошти)</label>
                                        </div>
                                        <input className={"form-control"}
                                               placeholder="user@example.com"
                                               autoComplete={"on"}
                                               id="username"
                                               readOnly={verificationCodeSent}
                                               {...register("username", {required: true})}
                                               type="email"/>
                                        <ErrorMessage field={"Логін"} type={errors["username"]?.type}/>
                                    </div>
                                    <div className="row gx-3 pe-0">
                                        <div className="form-group col-lg-6 pe-0">
                                            <div className="form-label-group">
                                                <label htmlFor="first_name">Ім’я</label>
                                            </div>
                                            <input className={"form-control"}
                                                   autoComplete={"on"}
                                                   placeholder="Введіть ім’я"
                                                   id="first_name"
                                                   readOnly={verificationCodeSent}
                                                   {...register("first_name", {required: true})}
                                                   type="text"/>
                                            <ErrorMessage field={"Ім’я"} type={errors["first_name"]?.type}/>
                                        </div>
                                        <div className="form-group col-lg-6 pe-0">
                                            <div className="form-label-group">
                                                <label htmlFor="last_name">Прізвище</label>
                                            </div>
                                            <input className={"form-control"}
                                                   placeholder="Введіть прізвище"
                                                   id="last_name"
                                                   readOnly={verificationCodeSent}
                                                   {...register("last_name", {required: true})}
                                                   type="text"/>
                                            <ErrorMessage field={"Прізвище"} type={errors["last_name"]?.type}/>
                                        </div>
                                    </div>
                                    <div className="row gx-3 pe-0">
                                        <div className="form-group col-lg-12 pe-0">
                                            <div className="form-label-group">
                                                <label htmlFor="phone_number">Номер телефону</label>
                                            </div>
                                            <input className={"form-control"}
                                                   placeholder="380971234567"
                                                   id="phone_number"
                                                   readOnly={verificationCodeSent}
                                                   {...register("phone_number", {required: true})}
                                                   type="text"/>
                                            <ErrorMessage field={"Номер телефону"} type={errors["phone_number"]?.type}/>
                                        </div>
                                    </div>
                                    {/*password*/}
                                    <PassInput label="Пароль" name="password1" readOnly={verificationCodeSent}/>
                                    {/*password2*/}
                                    <PassInput label="Пароль (підтвердження)" name="password2"
                                               readOnly={verificationCodeSent}/>
                                    <div className="row gx-3 pe-0 mb-3">
                                        <ListGroup as="ol" variant="flush" className="border-0">
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може бути надто схожим на іншу особисту інформацію.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може бути одним із дуже поширених.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Ваш пароль повинен містити як мінімум 8 символів. Букви, цифри,
                                                символи.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Ваш пароль повинен містити як мінімум одну велику і одну маленьку
                                                букви.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Ваш пароль повинен містити як мінімум один iз символів: # @ $ & %.
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" className="text-muted fs-7 border-0 py-0">
                                                - Пароль не може складається лише із цифр.
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>


                                    {verificationCodeSent ?
                                        <div className="row gx-3 pe-0">
                                            <div className="form-group col-lg-12 pe-0">
                                                <div className="form-label-group">
                                                    <label htmlFor="verification_code">Код підтвердження</label>
                                                </div>
                                                <input className={"form-control"}
                                                       pattern={"[0-9]{6}"}
                                                       placeholder="xxxxxx"
                                                       readOnly={!verificationCodeSent}
                                                       id="verification_code"
                                                       {...register("verification_code", {required: true})}
                                                       type="text"/>
                                                <ErrorMessage field={"Код підтвердження"}
                                                              type={errors["verification_code"]?.type}/>
                                            </div>
                                        </div> : <></>
                                    }

                                </fieldset>
                            </div>
                            {!verificationCodeSent && !loading ?
                                <div className="row gx-3 mb-3">
                                    <div className="col-lg-10 mx-auto d-flex justify-content-center">
                                        <ReCAPTCHA ref={recaptchaRef}
                                                   hl={'uk'}
                                                   sitekey={reCaptchaKey}
                                                   onChange={handleRecaptcha}
                                                   theme={'light'}/>
                                    </div>
                                </div> : <></>
                            }
                            <button type="submit" className="btn btn-primary btn-uppercase btn-block" name="submit"
                                    disabled={!capchaToken}
                                    onClick={() => {
                                        clearErrors()
                                    }}>
                                {verificationCodeSent ?
                                    'Підтвердити реєстрацію' : 'Зареєструвати користувача'
                                }
                            </button>
                            <p className="p-xs mt-2 text-center mb-5">
                                <a className="text-dark-60" href="/login">Вже є обліковий запис? &nbsp;&nbsp;
                                    <u className="text-primary">Вхід</u>
                                </a>
                            </p>

                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

const Signup = () => {

    const redirectUrl = useSelector(state => state.Auth2.redirectUrl)
    const navigate = useNavigate();

    useEffect(() => {
        if (redirectUrl)
            setTimeout(() => navigate(redirectUrl), 1000)
        else
            CSRF().then()
    }, [redirectUrl, navigate]);

    return (
        <div className={"hk-wrapper hk-pg-auth"} data-footer={"simple"}>
            <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                <BrandNav/>
            </nav>

            <div className={"hk-pg-wrapper"}>
                <AuthBody>
                    <SignUpContent/>
                </AuthBody>
                <Footer/>
            </div>

        </div>
    )
}

export default Signup