import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
    faCircle as faSolidCircle,
    faCircleHalfStroke, faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import {
    faCircle,
    faRegistered
} from "@fortawesome/free-regular-svg-icons";

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Popover, ListGroup} from 'react-bootstrap';
import {emptyOrValue} from "../../Utils/utils";

const TableRow = ({row}) => {

    const status_pay_id = row.status_pay === undefined ? -1 : row.status_pay.id

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {
                row.status_pay === undefined ? "" : row.status_pay.status
            }
        </Tooltip>
    );

    return (
        <tr className="odd">
            <td className="sorting_1">
                <div className="media align-items-center">
                    <div className="media-body">
                        <div className="text-high-em">
                            <a href={`/orders/order-details/${row.uid}`} className="table-link-text link-high-em">
                                {row.r === true ?
                                    <FontAwesomeIcon icon={faRegistered} className="fs--1 me-1 text-warning"/> :
                                    <></>
                                }
                                {row.number}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="fs-7">{row.date}</div>
            </td>
            <td>
                <div className=""> {row.delivery_date}</div>
                <div className="fs-7"> {row.delivery_time}</div>
            </td>
            <td>
                <OverlayTrigger placement="left" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
                    <span>
                        {(() => {
                            switch (status_pay_id) {
                                case 0 :
                                    return <FontAwesomeIcon icon={faSolidCircle} className="fs-4 me-2 text-success"/>
                                case 1 :
                                    return <FontAwesomeIcon icon={faCircleHalfStroke}
                                                            className="fs-4 me-2 text-warning"/>
                                case 2 :
                                    return <FontAwesomeIcon icon={faCircle} className="fs-4 me-2 text-danger"/>
                                default:
                                    return <FontAwesomeIcon icon={faCircle} className="fs-4 me-2 text-danger"/>
                            }
                        })()}
                    </span>
                </OverlayTrigger>
                <span className="badge badge-smoke mb-2">{row.status_order}</span>
            </td>
            <td>
                <div className="text-dark">{row.profile_system}</div>
                <div className="fs-7">{row.color}</div>
            </td>
            {/*<td>{row.profile_type}</td>*/}
            <td>
                <div className="">{row.structures}</div>
            </td>
            {/*<td>*/}
            {/*    <div>{row.weight}</div>*/}
            {/*</td>*/}
            {/*<td>{row.weight}</td>*/}
            <td>{row.delivery_address}
            </td>

            <td>{emptyOrValue(row.amount)}</td>
            <td>
                <div className="text-dark">{row.manager === undefined ? "" : row.manager.last_name}</div>
                <div className="text-dark">{row.manager === undefined ? "" : row.manager.first_name}</div>
            </td>
        </tr>
    )
}

const Table = ({data}) => {

    const popover1 = (
        <Popover id="popover-basic">
            <Popover.Header><h6>Статуси оплат</h6></Popover.Header>
            <Popover.Body>
                <ListGroup>
                    <ListGroup.Item className="border-0 px-0">
                        <span className="d-block badge-status lh-1">
                            <FontAwesomeIcon icon={faSolidCircle} className="fs-7 me-2 text-success "/>
                            <span className="badge-label d-inline-block">Сплачено</span>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 px-0">
                        <span className="d-block badge-status lh-1">
                            <FontAwesomeIcon icon={faCircleHalfStroke} className="fs-6 me-2 text-warning"/>
                            <span className="badge-label d-inline-block">Сплачено частково</span>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 px-0">
                        <span className="d-block badge-status lh-1">
                            <FontAwesomeIcon icon={faCircle} className="fs-6 me-2 text-danger"/>
                            <span className="badge-label d-inline-block">Несплачено</span>
                        </span>
                    </ListGroup.Item>
                </ListGroup>
            </Popover.Body>
        </Popover>

    );

    return (
        <table id="datable_1" className="table nowrap mb-5 dataTable no-footer" role="grid"
               aria-describedby="datable_1_info">
            <thead>
            <tr role="row">
                <th className="sorting_disabled" tabIndex="0" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Замовлення #
                </th>
                <th className="sorting_disabled" tabIndex="1" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Відвантаження
                </th>
                <th className="sorting_disabled" tabIndex="2" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Статуси
                    <OverlayTrigger placement="left" delay={{show: 250, hide: 400}} overlay={popover1}>
                        <FontAwesomeIcon icon={faCircleInfo} className="fs-7 mb-2 text-info"/>
                    </OverlayTrigger>
                </th>
                <th className="sorting_disabled" tabIndex="3" aria-controls="datable_1" rowSpan="1"
                    colSpan="1"
                >
                    Профіль/Колір
                </th>
                {/*<th className="sorting_disabled" tabIndex="4" aria-controls="datable_1" rowSpan="1"*/}
                {/*    colSpan="1">*/}
                {/*    Тип*/}
                {/*</th>*/}
                <th className="sorting_disabled" tabIndex="5" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Констр.
                </th>
                {/*<th className="sorting_disabled" tabIndex="6" aria-controls="datable_1" rowSpan="1"*/}
                {/*    colSpan="1">*/}
                {/*    Вага (кг)*/}
                {/*</th>*/}
                <th className="sorting_disabled" tabIndex="7" aria-controls="datable_1" rowSpan="1"
                    style={{width: "263.5px"}}>
                    Адреса доставки
                </th>
                <th className="sorting_disabled" tabIndex="7" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Сума
                </th>
                <th className="sorting_disabled" tabIndex="10" aria-controls="datable_1" rowSpan="1"
                    colSpan="1">
                    Менеджер
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data.map(el => <TableRow key={el.row_id} row={el}/>)
            }
            </tbody>
        </table>
    )
}


export default Table