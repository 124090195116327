import {useState} from "react";
import {faCalendarDays, faMapLocationDot, faPrint} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import DateWindow from "./DateWindow";
import AddressWindow from "./AddressWindow";
import PrintWindow from "./PrintWindow";

const AdditionalPanel = ({uid, delivery_address, delivery_date}) => {

    const [showDateWindow, setShowDateWindow] = useState(false)
    const [showAddressWindow, setShowAddressWindow] = useState(false)
    const [showPrintWindow, setShowPrintWindow] = useState(false)

    return (
        <>
            <div className="d-flex mt-3">
                <AdditionalPanelButton icon={faPrint} title={"Друкувати"} setShowWindow={setShowPrintWindow}
                                       className={"btn btn-sm btn-light btn-block"}/>
                <PrintWindow show={showPrintWindow} showHandler={setShowPrintWindow} uid={uid}/>
                <AdditionalPanelButton icon={faEnvelope} title={"Написати"}
                                       className={"btn btn-sm btn-light btn-block ms-2 mt-0"}/>
            </div>

            <div className="d-flex mt-3">
                <AdditionalPanelButton icon={faCalendarDays} title={"Змінити дату"} setShowWindow={setShowDateWindow}
                                       className={"btn btn-sm btn-light btn-block"}/>
                <DateWindow show={showDateWindow} showHandler={setShowDateWindow}
                            uid={uid} delivery_date={delivery_date}/>
                <AdditionalPanelButton icon={faMapLocationDot} title={"Змінити адресу"} setShowWindow={setShowAddressWindow}
                                       className={"btn btn-sm btn-light btn-block ms-2 mt-0"}/>
                <AddressWindow show={showAddressWindow} showHandler={setShowAddressWindow}
                               uid={uid} address={delivery_address}/>
            </div>
        </>
    )
}

const AdditionalPanelButton = ({icon, title, className, setShowWindow}) => {
    return (
        <button className={className} onClick={() => {
            setShowWindow(true)
        }}>
            <span><span className="icon"><span className="feather-icon">
                <FontAwesomeIcon icon={icon} className="feather"/>
            </span></span><span>{title}</span></span>
        </button>
    )
}

export default AdditionalPanel